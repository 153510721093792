import React, { useState, useEffect } from "react";
// import CartEmptyState from "../components/Cart/cart-empty-state"; 
import CartListing from "../components/Cart/CartListing";
import apiCartService from "../services/cartService";

const CartManagementPage = () => {
  const [carts, setCarts] = useState([]);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [filterTerm, setFilterTerm] = useState('');

  useEffect(() => {
    fetchCarts();
  }, []);

  const fetchCarts = async () => {
    try {
      const response = await apiCartService.getCarts();
      setCarts(response.data);
    } catch (error) {
      console.error('Error fetching carts:', error);
    }
  };

  const handleDeleteCart = async (cartId) => {
    if (window.confirm('Are you sure you want to delete this cart?')) {
      try {
        await apiCartService.deleteCart(cartId);
        setCarts(carts.filter(cart => cart.id !== cartId));
        setMessage('Cart deleted successfully!');
        setMessageType('success');
      } catch (error) {
        setMessage('Failed to delete cart.');
        setMessageType('error');
      }
    }
  };

  const handleUpdateCart = async (updatedCart) => {
    try {
      await apiCartService.updateCart(updatedCart);
      setCarts(carts.map(cart => (cart.id === updatedCart.id ? updatedCart : cart)));
      setMessage('Cart updated successfully!');
      setMessageType('success');
    } catch (error) {
      setMessage('Failed to update cart.');
      setMessageType('error');
    }
  };

  const handleAssignVendor = async (cart, vendor) => {
    if (window.confirm('Are you sure you want to change the vendor for this cart?')) {
      const updatedCart = { ...cart, vendor };
      handleUpdateCart(updatedCart);
    }
  };

  const handleFilterChange = (e) => {
    setFilterTerm(e.target.value);
  };

  const filteredCarts = carts.filter(cart =>
    cart.name.toLowerCase().includes(filterTerm.toLowerCase()) ||
    cart.vendor?.name.toLowerCase().includes(filterTerm.toLowerCase()) ||
    cart.mobileNo.includes(filterTerm) ||
    cart.address.societyName.toLowerCase().includes(filterTerm.toLowerCase()) ||
    cart.address.city.toLowerCase().includes(filterTerm.toLowerCase()) ||
    cart.address.state.toLowerCase().includes(filterTerm.toLowerCase())
  );

  return (
    <div>
      <div className="container-sub-header">
        <div className="left-buttons">
          <input
            type="text"
            className="filter-input"
            placeholder="Filter carts"
            value={filterTerm}
            onChange={handleFilterChange}
          />
        </div>
      </div>
      
      <div className="cart-container">
        <CartListing
          carts={filteredCarts}
          onDeleteCart={handleDeleteCart}
          onUpdateCart={handleUpdateCart}
          onAssignVendor={handleAssignVendor}
        />
      </div>
      {message && <div className={`message ${messageType}`}>{message}</div>}
    </div>
  );
};

export default CartManagementPage;