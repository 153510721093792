import React, { useState } from 'react';
import './CartListing.css';

const CartListing = ({ carts = [], onDeleteCart, onUpdateCart, onAssignVendor }) => {
  const [showVendorPopup, setShowVendorPopup] = useState(false);
  const [selectedCart, setSelectedCart] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editCart, setEditCart] = useState({});
  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [currentPage, setCurrentPage] = useState(1);
  const cartsPerPage = 5;

  const handleAssignVendor = (cart) => {
    setSelectedCart(cart);
    setShowVendorPopup(true);
  };

  const handleVendorSelect = (vendor) => {
    onAssignVendor(selectedCart, vendor);
    setShowVendorPopup(false);
  };

  const handleEditCart = (cart) => {
    setIsEditing(true);
    setEditCart(cart);
  };

  const handleSaveEdit = () => {
    onUpdateCart(editCart);
    setIsEditing(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'startTime' || name === 'endTime') {
      setEditCart({
        ...editCart,
        timings: {
          ...editCart.timings,
          [name]: value,
        },
      });
    } else if (name === 'societyName' || name === 'city' || name === 'state') {
      setEditCart({
        ...editCart,
        address: {
          ...editCart.address,
          [name]: value,
        },
      });
    } else {
      setEditCart({ ...editCart, [name]: value });
    }
  };

  const handleSort = (field) => {
    const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    setSortOrder(order);
  };

  const sortedCarts = [...carts].sort((a, b) => {
    if (a[sortField] < b[sortField]) return sortOrder === 'asc' ? -1 : 1;
    if (a[sortField] > b[sortField]) return sortOrder === 'asc' ? 1 : -1;
    return 0;
  });

  const indexOfLastCart = currentPage * cartsPerPage;
  const indexOfFirstCart = indexOfLastCart - cartsPerPage;
  const currentCarts = sortedCarts.slice(indexOfFirstCart, indexOfLastCart);

  const getSortArrow = (field) => {
    if (sortField === field) {
      return sortOrder === 'asc' ? '↑' : '↓';
    }
    return '';
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="cart-listing">
      <table>
        <thead>
          <tr>
            <th onClick={() => handleSort('id')}>ID {getSortArrow('id')}</th>
            <th onClick={() => handleSort('name')}>Name {getSortArrow('name')}</th>
            <th onClick={() => handleSort('vendor')}>Vendor {getSortArrow('vendor')}</th>
            <th onClick={() => handleSort('mobileNo')}>Mobile No {getSortArrow('mobileNo')}</th>
            <th onClick={() => handleSort('address')}>Address {getSortArrow('address')}</th>
            <th onClick={() => handleSort('timings')}>Timings {getSortArrow('timings')}</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentCarts.length > 0 ? (
            currentCarts.map((cart) => (
              <tr key={cart.id}>
                <td>{cart.id}</td>
                <td>
                  {isEditing && editCart.id === cart.id ? (
                    <input
                      type="text"
                      name="name"
                      value={editCart.name}
                      onChange={handleChange}
                    />
                  ) : (
                    cart.name
                  )}
                </td>
                <td>{cart.vendor ? `${cart.vendor.name} : (${cart.vendor.phone})` : 'No Vendor'}</td>
                <td>
                  {isEditing && editCart.id === cart.id ? (
                    <input
                      type="text"
                      name="mobileNo"
                      value={editCart.mobileNo}
                      onChange={handleChange}
                    />
                  ) : (
                    cart.mobileNo
                  )}
                </td>
                <td>
                  {isEditing && editCart.id === cart.id ? (
                    <>
                      <input
                        type="text"
                        name="societyName"
                        value={editCart.address.societyName}
                        onChange={handleChange}
                      />
                      <input
                        type="text"
                        name="city"
                        value={editCart.address.city}
                        onChange={handleChange}
                      />
                      <input
                        type="text"
                        name="state"
                        value={editCart.address.state}
                        onChange={handleChange}
                      />
                    </>
                  ) : (
                    `${cart.address.societyName}, ${cart.address.city}, ${cart.address.state}`
                  )}
                </td>
                <td>
                  {isEditing && editCart.id === cart.id ? (
                    <>
                      <input
                        type="time"
                        name="startTime"
                        value={editCart.timings?.startTime || ''}
                        onChange={handleChange}
                      />
                      <input
                        type="time"
                        name="endTime"
                        value={editCart.timings?.endTime || ''}
                        onChange={handleChange}
                      />
                    </>
                  ) : (
                    `${cart.timings?.startTime || ''} - ${cart.timings?.endTime || ''}`
                  )}
                </td>
                <td>
                  {isEditing && editCart.id === cart.id ? (
                    <>
                      <button onClick={handleSaveEdit}>Save</button>
                      <button onClick={() => setIsEditing(false)}>Cancel</button>
                    </>
                  ) : (
                    <>
                      <button onClick={() => handleEditCart(cart)}>Edit</button>
                      <button onClick={() => onDeleteCart(cart.id)}>Delete</button>
                      <button onClick={() => handleAssignVendor(cart)}>Assign Vendor</button>
                    </>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7">No carts available</td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="pagination">
        {Array.from({ length: Math.ceil(carts.length / cartsPerPage) }, (_, i) => (
          <button
            key={i + 1}
            onClick={() => handlePageChange(i + 1)}
            className={currentPage === i + 1 ? 'active' : ''}
          >
            {i + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default CartListing;