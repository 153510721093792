import React, { useState } from 'react';
import './ProductListing.css';

const ProductListing = ({ products, onDelete, onEdit }) => {
  const [sortKey, setSortKey] = useState('productName');
  const [sortOrder, setSortOrder] = useState('asc');
  const [filter, setFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedProducts, setSelectedProducts] = useState([]); // Track selected products
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    category: '',
    subCategories: [],
    priceRange: { min: '', max: '' },
    measureUnits: [],
    weightRange: { min: '', max: '' },
    productName: '',
  });
  const [appliedFilterOptions, setAppliedFilterOptions] = useState(filterOptions);
  const itemsPerPage = 10;

  // Handle Sorting
  const handleSort = (key) => {
    if (sortKey === key) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortKey(key);
      setSortOrder('asc');
    }
  };

  // Sorting Logic
  const sortedProducts = [...products].sort((a, b) => {
    if (a[sortKey] < b[sortKey]) return sortOrder === 'asc' ? -1 : 1;
    if (a[sortKey] > b[sortKey]) return sortOrder === 'asc' ? 1 : -1;
    return 0;
  });

  // Filtering Logic
  const filteredProducts = sortedProducts.filter((product) => {
    const matchesCategory = appliedFilterOptions.category ? product.category === appliedFilterOptions.category : true;
    const matchesSubCategories = appliedFilterOptions.subCategories.length > 0 ? appliedFilterOptions.subCategories.includes(product.subCategory) : true;
    const matchesPriceRange = (appliedFilterOptions.priceRange.min === '' || product.price >= appliedFilterOptions.priceRange.min) &&
                              (appliedFilterOptions.priceRange.max === '' || product.price <= appliedFilterOptions.priceRange.max);
    const matchesMeasureUnits = appliedFilterOptions.measureUnits.length > 0 ? appliedFilterOptions.measureUnits.includes(product.measureUnit) : true;
    const matchesWeightRange = (appliedFilterOptions.weightRange.min === '' || product.weight >= appliedFilterOptions.weightRange.min) &&
                               (appliedFilterOptions.weightRange.max === '' || product.weight <= appliedFilterOptions.weightRange.max);
    const matchesProductName = appliedFilterOptions.productName ? product.productName.toLowerCase().includes(appliedFilterOptions.productName.toLowerCase()) : true;

    return matchesCategory && matchesSubCategories && matchesPriceRange && matchesMeasureUnits && matchesWeightRange && matchesProductName;
  });

  // Pagination Logic
  const paginatedProducts = filteredProducts.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const renderSortArrow = (key) => {
    if (sortKey === key) {
      return sortOrder === 'asc' ? '↑' : '↓';
    }
    return '';
  };

  // Handle individual checkbox change
  const handleCheckboxChange = (productId) => {
    setSelectedProducts((prevSelected) => {
      if (prevSelected.includes(productId)) {
        return prevSelected.filter((id) => id !== productId);
      } else {
        return [...prevSelected, productId];
      }
    });
  };

  // Handle "Select All" checkbox change
  const handleSelectAllChange = (e) => {
    if (e.target.checked) {
      setSelectedProducts(paginatedProducts.map((product) => product.id));
    } else {
      setSelectedProducts([]);
    }
  };

  // Check if all items are selected for "Select All" checkbox state
  const isAllSelected = paginatedProducts.length > 0 && paginatedProducts.every((product) =>
    selectedProducts.includes(product.id)
  );

  // Handle filter panel toggle
  const toggleFilterPanel = () => {
    setIsFilterPanelOpen(!isFilterPanelOpen);
  };

  // Handle filter option changes
  const handleFilterOptionChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFilterOptions((prevOptions) => ({
        ...prevOptions,
        [name]: checked ? [...prevOptions[name], value] : prevOptions[name].filter((v) => v !== value),
      }));
    } else if (name === 'subCategories') {
      const options = Array.from(e.target.selectedOptions, (option) => option.value);
      setFilterOptions((prevOptions) => ({
        ...prevOptions,
        subCategories: options,
      }));
    } else if (name === 'priceRangeMin' || name === 'priceRangeMax') {
      setFilterOptions((prevOptions) => ({
        ...prevOptions,
        priceRange: {
          ...prevOptions.priceRange,
          [name === 'priceRangeMin' ? 'min' : 'max']: value,
        },
      }));
    } else if (name === 'weightRangeMin' || name === 'weightRangeMax') {
      setFilterOptions((prevOptions) => ({
        ...prevOptions,
        weightRange: {
          ...prevOptions.weightRange,
          [name === 'weightRangeMin' ? 'min' : 'max']: value,
        },
      }));
    } else {
      setFilterOptions((prevOptions) => ({
        ...prevOptions,
        [name]: value,
      }));
    }
  };

  // Handle Apply Filters
  const handleApplyFilters = () => {
    setAppliedFilterOptions(filterOptions);
    setIsFilterPanelOpen(false);
  };

  return (
    <div className="product-listing">
      <div className="filter-sort">
        <button className="action-button active filter-button" onClick={toggleFilterPanel}>
          {isFilterPanelOpen ? 'Close Filters' : 'Open Filters'}
        </button>
      </div>

      {isFilterPanelOpen && (
        <div className="filter-panel">
          <div className="filter-row">
            <div className="filter-group">
              <label>Category:</label>
              <div className="radio-filter">
                <label>
                  <input
                    type="radio"
                    name="category"
                    value="Fruits"
                    checked={filterOptions.category === 'Fruits'}
                    onChange={handleFilterOptionChange}
                  />
                  Fruits
                </label>
                <label>
                  <input
                    type="radio"
                    name="category"
                    value="Vegetables"
                    checked={filterOptions.category === 'Vegetables'}
                    onChange={handleFilterOptionChange}
                  />
                  Vegetables
                </label>
              </div>
            </div>

            <div className="filter-group">
              <label>Sub-category:</label>
              <select
                name="subCategories"
                multiple
                value={filterOptions.subCategories}
                onChange={handleFilterOptionChange}
              >
                <option value="Exotic Fruit">Exotic Fruit</option>
                <option value="Seasonal Fruit">Seasonal Fruit</option>
                <option value="Offer Fruit">Offer Fruit</option>
                <option value="High Demand Fruit">High Demand Fruit</option>
                <option value="Exotic Vegetables">Exotic Vegetables</option>
                <option value="Seasonal Vegetables">Seasonal Vegetables</option>
                <option value="Offer Vegetables">Offer Vegetables</option>
                <option value="High Demand Vegetables">High Demand Vegetables</option>
              </select>
            </div>
          </div>

          <div className="filter-row">
            <div className="filter-group">
              <label>Price Range:</label>
              <div>
                <input
                  className="price-input"
                  type="number"
                  name="priceRangeMin"
                  value={filterOptions.priceRange.min}
                  onChange={handleFilterOptionChange}
                  placeholder="Min"
                />
                <input
                  className="price-input"
                  type="number"
                  name="priceRangeMax"
                  value={filterOptions.priceRange.max}
                  onChange={handleFilterOptionChange}
                  placeholder="Max"
                />
              </div>
            </div>

            <div className="filter-group">
              <label>Measure Unit:</label>
              <div className="radio-filter">
                <label>
                  <input
                    type="checkbox"
                    name="measureUnits"
                    value="Gms"
                    checked={filterOptions.measureUnits.includes('Gms')}
                    onChange={handleFilterOptionChange}
                  />
                  Gms
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="measureUnits"
                    value="Kgs"
                    checked={filterOptions.measureUnits.includes('Kgs')}
                    onChange={handleFilterOptionChange}
                  />
                  Kgs
                </label>
              </div>
            </div>
          </div>
          
          <div className="filter-row">
            <div className="filter-group">
              <label>Weight Range:</label>
              <div>
                <input
                  className="price-input"
                  type="number"
                  name="weightRangeMin"
                  value={filterOptions.weightRange.min}
                  onChange={handleFilterOptionChange}
                  placeholder="Min"
                />
                <input
                  className="price-input"
                  type="number"
                  name="weightRangeMax"
                  value={filterOptions.weightRange.max}
                  onChange={handleFilterOptionChange}
                  placeholder="Max"
                />
              </div>
            </div>

            <div className="filter-group">
              <label>Product Name:</label>
              <input
                type="text"
                name="productName"
                value={filterOptions.productName}
                onChange={handleFilterOptionChange}
                placeholder="Product Name"
              />
            </div>
          </div>

          <div className="filter-actions">
            <button className="apply-button" onClick={handleApplyFilters}>Apply</button>
          </div>
        </div>
      )}

      <table className="product-table">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                checked={isAllSelected}
                onChange={handleSelectAllChange} // Handle the select all checkbox click
              />
            </th>
            <th>Product</th>
            <th onClick={() => handleSort('productId')}>Product Id {renderSortArrow('productId')}</th>
            <th onClick={() => handleSort('productName')}>Name {renderSortArrow('productName')}</th>
            <th onClick={() => handleSort('price')}>Price {renderSortArrow('price')}</th>
            <th onClick={() => handleSort('weight')}>Weight {renderSortArrow('weight')}</th>
            <th onClick={() => handleSort('subCategory')}>Sub-category {renderSortArrow('subCategory')}</th>
            <th onClick={() => handleSort('quantity')}>Total Stock {renderSortArrow('quantity')}</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {paginatedProducts.map((product) => (
            <tr key={product.id}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedProducts.includes(product.id)} // Check if this product is selected
                  onChange={() => handleCheckboxChange(product.id)} // Handle individual checkbox change
                />
              </td>
              <td>
                {product.image ? (
                  <img src={product.image} alt={product.productName} width="50" height="50" />
                ) : (
                  'No image'
                )}
              </td>
              <td>{product.id}</td>
              <td>{product.productName}</td>
              <td>{product.price}</td>
              <td>{product.weight} {product.measureUnit}</td>
              <td>{product.subCategory}</td>
              <td>{product.quantity}</td>
              <td>
                {/* Edit Button */}
                <button
                  className="btn action-button"
                  onClick={() => onEdit(product.id)} // Call the parent-provided edit handler
                >
                  Edit
                </button>

                {/* Delete Button */}
                <button
                  className="btn action-button"
                  onClick={() =>
                    window.confirm('Are you sure you want to delete this product?') &&
                    onDelete(product.id)
                  }
                >
                  Delete
                </button>

                {/* Stock Warning */}
                {product.quantity < 10 && (
                  <span className="stock-warning">Item going out of stock</span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="pagination">
        {Array.from({ length: Math.ceil(filteredProducts.length / itemsPerPage) }, (_, i) => (
          <button
            key={i + 1}
            onClick={() => setCurrentPage(i + 1)}
            className={currentPage === i + 1 ? 'active' : ''}
          >
            {i + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default ProductListing;