import React, { useState } from 'react';
import './AddProduct.css';

const AddProduct = ({ onClose, onSubmit }) => {
  const [formData, setFormData] = useState({
    productName: '',
    weight: '',
    weightUnit: 'Gms',
    quantity: '',
    price: '',
    category: 'Vegetables',
    subCategory: '',
    description: '',
    images: [], // Updated to handle multiple files
  });
  const [error, setError] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    setFormData((prevData) => ({
      ...prevData,
      images: [...prevData.images, ...files],
    }));
  };

  const handleRemoveImage = (index) => {
    setFormData((prevData) => {
      const updatedImages = prevData.images.filter((_, i) => i !== index);
      return { ...prevData, images: updatedImages };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !formData.productName ||
      !formData.weight ||
      !formData.quantity ||
      !formData.price ||
      !formData.description ||
      formData.images.length === 0
    ) {
      setError('All fields are required!');
      return;
    }
    setError('');
    onSubmit(formData);
  };

  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <h2 className="popup-header">Add Product</h2>
        <form onSubmit={handleSubmit}>
          {/* Product Name and Weight */}
          <div className="popup-row">
            <div className="form-group">
              <label>Product Name</label>
              <input
                type="text"
                name="productName"
                value={formData.productName}
                onChange={handleInputChange}
                placeholder="Product Name"
                required
              />
            </div>
            <div className="form-group">
              <label>Weight</label>
              <div className="weight-input">
                <input
                  type="number"
                  name="weight"
                  value={formData.weight}
                  onChange={handleInputChange}
                  placeholder="Weight of the Item"
                  required
                />
                <select
                  name="weightUnit"
                  value={formData.weightUnit}
                  onChange={handleInputChange}
                >
                  <option value="Gms">Gms</option>
                  <option value="Kgs">Kgs</option>
                </select>
              </div>
            </div>
          </div>

          {/* Quantity and Price */}
          <div className="popup-row">
            <div className="form-group">
              <label>Quantity</label>
              <input
                type="number"
                name="quantity"
                value={formData.quantity}
                onChange={handleInputChange}
                placeholder="No. of quantity"
                required
              />
            </div>
            <div className="form-group">
              <label>Price</label>
              <input
                type="number"
                name="price"
                value={formData.price}
                onChange={handleInputChange}
                placeholder="Price of item"
                required
              />
            </div>
          </div>

          {/* Category and Subcategory */}
          <div className="popup-row">
            <div className="form-group">
              <label>Category</label>
              <div className="radio-group">
                <span>
                  <input
                    type="radio"
                    name="category"
                    value="Vegetables"
                    checked={formData.category === 'Vegetables'}
                    onChange={handleInputChange}
                  />
                  Vegetables
                </span>
                <span>
                  <input
                    type="radio"
                    name="category"
                    value="Fruits"
                    checked={formData.category === 'Fruits'}
                    onChange={handleInputChange}
                  />
                  Fruits
                </span>
              </div>
            </div>
            <div className="form-group">
              <label>Sub Category</label>
              <select
                name="subCategory"
                value={formData.subCategory}
                onChange={handleInputChange}
                required
              >
                <option value="">Select subcategory</option>
                <option value="Seasonal Vegetable">Seasonal Vegetable</option>
                <option value="Leafy Vegetable">Leafy Vegetable</option>
                <option value="Green Vegetable">Green Vegetable</option>
                <option value="Offer Vegetable">Offer Vegetable</option>
              </select>
            </div>
          </div>

          {/* Description */}
          <div className="popup-row">
            <div className="form-group">
              <label>Description</label>
              <textarea
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                placeholder="Write description about the product"
                required
              ></textarea>
            </div>
          </div>

          {/* Image Upload */}
          <div className="popup-row">
            <div className="form-group">
              <label>Add Images</label>
              <div className="upload-section">
                <input
                  type="file"
                  multiple
                  onChange={handleImageUpload}
                  accept="image/*"
                />
              </div>
            </div>
          </div>

          <div className="popup-row">
              <div className="image-preview-container">
                {formData.images.map((image, index) => (
                  <div key={index} className="image-preview">
                    <img src={URL.createObjectURL(image)} alt="Preview" />
                    <button
                      type="button"
                      className="remove-image-button"
                      onClick={() => handleRemoveImage(index)}
                    >
                      ✕
                    </button>
                  </div>
                ))}
              </div>
          </div>

          {/* Actions */}
          <div className="popup-actions">
            <button type="button" className="action-button cancel-button" onClick={onClose}>
              Cancel
            </button>
            <button type="submit" className="action-button active save-button">
              Save
            </button>
          </div>
        </form>
        {error && <div className="error">{error}</div>}
      </div>
    </div>
  );
};

export default AddProduct;
