// import axios from "axios";

let carts = [
  {
    id: 1,
    name: "Fresh Veggies Cart",
    vendor: { id: 1, name: "Vendor 1", phone: "1234567890" },
    mobileNo: "1234567890",
    address: {
      societyName: "Green Valley Society",
      city: "New York",
      state: "NY"
    },
    timings: { startTime: "08:00", endTime: "18:00" }
  },
  {
    id: 2,
    name: "Organic Fruits Cart",
    vendor: { id: 2, name: "Vendor 2", phone: "0987654321" },
    mobileNo: "0987654321",
    address: {
      societyName: "Sunshine Apartments",
      city: "Los Angeles",
      state: "CA"
    },
    timings: { startTime: "09:00", endTime: "19:00" }
  },
  {
    id: 3,
    name: "Daily Essentials Cart",
    vendor: { id: 3, name: "Vendor 3", phone: "1122334455" },
    mobileNo: "1122334455",
    address: {
      societyName: "Maple Residency",
      city: "Chicago",
      state: "IL"
    },
    timings: { startTime: "07:00", endTime: "17:00" }
  },
];

const apiCartService = {
  // Example API endpoint for getting cart data
  getCarts: async () => {
    try {
      // Uncomment the following line to integrate with the actual API
      // const response = await axios.get('/api/carts');

      // Dummy data for testing
      return { data: carts };
    } catch (error) {
      console.error("Error getting carts:", error);
      throw error;
    }
  },

  // Example API endpoint for adding a new cart
  addCart: async (cart) => {
    try {
      // Uncomment the following line to integrate with the actual API
      // const response = await axios.post('/api/carts', cartData);

      cart.id = carts.length ? carts[carts.length - 1].id + 1 : 1;
      carts.push(cart);
      return { data: cart };
    } catch (error) {
      console.error("Error adding cart:", error);
      throw error;
    }
  },

  // Example API endpoint for updating a cart
  updateCart: async (updatedCart) => {
    try {
      // Uncomment the following line to integrate with the actual API
      // const response = await axios.put(`/api/carts/${cartId}`, cartData);

      // Dummy data for testing
      carts = carts.map(cart => (cart.id === updatedCart.id ? updatedCart : cart));
      return { data: updatedCart };
    } catch (error) {
      console.error("Error updating cart:", error);
      throw error;
    }
  },

  // Example API endpoint for deleting a cart
  deleteCart: async (cartId) => {
    try {
      // Uncomment the following line to integrate with the actual API
      // await axios.delete(`/api/carts/${cartId}`);

      // Dummy data for testing
      carts = carts.filter(cart => cart.id !== cartId);
      return { data: cartId };
    } catch (error) {
      console.error("Error deleting cart:", error);
      throw error;
    }
  },
};

export default apiCartService;
