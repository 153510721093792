// import React, { useState, useEffect } from "react";
import React from "react";
import '../styles/OrderManagementPage.css';

const AdminManagementPage = () => {
  // const [message, setMessage] = useState("");
  // const [messageType, setMessageType] = useState("");

  return (
    <div>
      <div className="container-sub-header">
        <div className="left-buttons">
          <button className="action-button">User Maagement</button>
          <button className="action-button">Discounts/Offers</button>     
          <button className="action-button">Referer</button>         
        </div>
        <div className="right-buttons">
          <input type="text" placeholder="Search by name" value="Search by name" className="filter-input"/>
        </div>
      </div>

      <div className="order-container">
        {/* {message && <div className={`message ${messageType}`}>{message}</div>} */}
      </div>
    </div>
  );
};

export default AdminManagementPage;