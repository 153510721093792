const customerOrders = [
  {
    id: 1,
    customerId: 101,
    customer: "John Doe",
    date: "2024-11-01",
    total: 50.0,
    status: "Delivered",
    shippingAddress: "123 Main St, New York, NY",
    items: [
      { id: 1, name: "Apple", quantity: 2, price: 10.0 },
      { id: 2, name: "Banana", quantity: 5, price: 5.0 },
    ],
  },
  {
    id: 2,
    customerId: 102,
    customer: "Jane Smith",
    date: "2024-11-02",
    total: 75.0,
    status: "Pending",
    shippingAddress: "456 Elm St, Los Angeles, CA",
    items: [
      { id: 3, name: "Orange", quantity: 3, price: 15.0 },
      { id: 4, name: "Grapes", quantity: 1, price: 20.0 },
    ],
  },
  {
    id: 3,
    customerId: 103,
    customer: "Mike Johnson",
    date: "2024-11-03",
    total: 100.0,
    status: "Cancelled",
    shippingAddress: "789 Oak St, Chicago, IL",
    items: [{ id: 5, name: "Mango", quantity: 4, price: 25.0 }],
  },
  // Add more dummy orders as needed
];


const vendorOrders = [
  {
    id: 1,
    vendorId: 201,
    vendor: "Vendor A",
    date: "2024-11-01",
    total: 150.0,
    status: "Delivered",
    items: [
      { id: 1, name: "Apple", quantity: 10, price: 10.0 },
      { id: 2, name: "Banana", quantity: 20, price: 5.0 },
    ],
  },
  {
    id: 2,
    vendorId: 202,
    vendor: "Vendor B",
    date: "2024-11-01",
    total: 150.0,
    status: "Delivered",
    items: [
      { id: 1, name: "Potato", quantity: 1000, price: 2500.0 },
      { id: 2, name: "Tomato", quantity: 2000, price: 5000.0 },
    ],
  },
  {
    id: 3,
    vendorId: 203,
    vendor: "Vendor C",
    date: "2024-11-01",
    total: 150.0,
    status: "Delivered",
    items: [
      { id: 1, name: "Papaya", quantity: 100, price: 3000.0 },
      { id: 2, name: "Banana", quantity: 200, price: 8000.0 },
    ],
  },
  {
    id: 4,
    vendorId: 202,
    vendor: "Vendor A",
    date: "2024-11-02",
    total: 175.0,
    status: "Pending",
    items: [
      { id: 3, name: "Orange", quantity: 15, price: 15.0 },
      { id: 4, name: "Grapes", quantity: 10, price: 20.0 },
    ],
  },
  {
    id: 5,
    vendorId: 202,
    vendor: "Vendor B",
    date: "2024-11-02",
    total: 175.0,
    status: "Pending",
    items: [
      { id: 3, name: "Orange", quantity: 15, price: 15.0 },
      { id: 4, name: "Grapes", quantity: 10, price: 20.0 },
    ],
  },
  {
    id: 6,
    vendorId: 202,
    vendor: "Vendor C",
    date: "2024-11-02",
    total: 175.0,
    status: "Pending",
    items: [
      { id: 3, name: "Orange", quantity: 15, price: 15.0 },
      { id: 4, name: "Grapes", quantity: 10, price: 20.0 },
    ],
  },
  {
    id: 7,
    vendorId: 203,
    vendor: "Vendor C",
    date: "2024-11-03",
    total: 200.0,
    status: "Cancelled",
    items: [{ id: 5, name: "Grapes", quantity: 8, price: 250.0 }],
  },
  {
    id: 8,
    vendorId: 203,
    vendor: "Vendor A",
    date: "2024-11-03",
    total: 200.0,
    status: "Cancelled",
    items: [{ id: 5, name: "Grapes", quantity: 8, price: 250.0 }],
  },
  {
    id: 9,
    vendorId: 203,
    vendor: "Vendor C",
    date: "2024-11-03",
    total: 200.0,
    status: "Cancelled",
    items: [{ id: 5, name: "Grapes", quantity: 8, price: 250.0 }],
  },
  // Add more dummy orders as needed
];


const apiOrderService = {
  getCustomerOrders: async () => {
    // Simulate an API call
    return Promise.resolve(customerOrders);
  },
  getVendorOrders: async () => {
    // Simulate an API call
    return Promise.resolve(vendorOrders);
  },
  // Other service methods...
};

export default apiOrderService;