import React, { useState } from 'react';
import Papa from 'papaparse';
import { addProduct, uploadCSVProducts } from '../../services/productService';  

const ImportProduct = ({ onClose }) => {
  const [csvFile, setCsvFile] = useState(null);
  const [error, setError] = useState('');
  const [products, setProducts] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setCsvFile(file);
    setError('');

    if (file && file.type !== 'text/csv') {
      setError('Please upload a valid CSV file.');
      return;
    }

    if (file && file.size > 5000000) { // Example: 5MB size limit
      setError('File size should not exceed 5MB.');
      return;
    }

    // Parse the CSV file
    Papa.parse(file, {
      complete: (result) => {
        // Assuming the CSV has a header row
        const parsedData = result.data;
        const invalidRows = validateCsvData(parsedData);
        if (invalidRows.length) {
          setError('There are invalid rows in your file.');
        } else {
          setProducts(parsedData); // Set valid rows to display in the UI
        }
      },
      header: true, // Assume the CSV file has headers
      skipEmptyLines: true,
    });
  };

  const validateCsvData = (data) => {
    const invalidRows = [];
    data.forEach((row, index) => {
      // Example validation: Check for missing required fields
      if (!row.productName || !row.price || !row.weight || !row.subCategory) {
        invalidRows.push(index + 1); // Row numbers (1-indexed)
      }
    });
    return invalidRows;
  };

  const handleUpload = async () => {
    if (isUploading) return;

    setIsUploading(true);
    setError('');

    try {
      // Add each product to the local products array
      for (const product of products) {
        await addProduct(product);
      }
      alert('Products imported successfully!');
      onClose(); // Close the popup on success
    } catch (error) {
      setError('Failed to upload products. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="import-product-popup popup-overlay">
      <div className="popup-container">
        <h3>Import Products from CSV</h3>

        <div className="instructions">
          <p><strong>Instructions:</strong></p>
          <ul>
            <li>Upload a CSV file containing product information.</li>
            <li>File size must not exceed 5MB.</li>
            <li>Accepted file type: CSV (.csv).</li>
            <li>Click <a href="/sample/sample-products.csv" download>here</a> to download a sample CSV file.</li>
          </ul>
        </div>

        <div className="file-upload">
          <input
            type="file"
            accept=".csv"
            onChange={handleFileChange}
            disabled={isUploading}
          />
        </div>

        {error && <div className="error-message">{error}</div>}

        <div className="csv-preview">
          {products.length > 0 && (
            <table>
              <thead>
                <tr>
                  <th>Product Name</th>
                  <th>Price</th>
                  <th>Weight</th>
                  <th>Sub-category</th>
                </tr>
              </thead>
              <tbody>
                {products.map((product, index) => (
                  <tr key={index}>
                    <td>{product.productName}</td>
                    <td>{product.price}</td>
                    <td>{product.weight}</td>
                    <td>{product.subCategory}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        <div className="actions">
          <button
            className="action-button"
            onClick={handleUpload}
            disabled={isUploading || products.length === 0}
          >
            {isUploading ? 'Uploading...' : 'Upload Products'}
          </button>
          <button
            className="action-button"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImportProduct;