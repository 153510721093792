import React, { useState, useEffect } from 'react';
import './VendorList.css';

const VendorList = ({ vendors, onEdit, onDelete, onStatusChange, statusChangeSuccess }) => {
  const [sortedVendors, setSortedVendors] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'ascending' });
  const [currentPage, setCurrentPage] = useState(1);
  const [vendorsPerPage] = useState(10);

  useEffect(() => {
    let sortedData = [...vendors];

    // Sorting logic
    if (sortConfig !== null) {
      sortedData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }

    setSortedVendors(sortedData);
  }, [vendors, sortConfig]);

  // Pagination logic
  const indexOfLastVendor = currentPage * vendorsPerPage;
  const indexOfFirstVendor = indexOfLastVendor - vendorsPerPage;
  const currentVendors = sortedVendors.slice(indexOfFirstVendor, indexOfLastVendor);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getSortArrow = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? '↑' : '↓';
    }
    return '';
  };

  return (
    <div className="vendor-list">
      <table>
        <thead>
          <tr>
            <th onClick={() => handleSort('id')}>
              Id {getSortArrow('id')}
            </th>
            <th onClick={() => handleSort('name')}>
              Name {getSortArrow('name')}
            </th>
            <th onClick={() => handleSort('email')}>
              Email {getSortArrow('email')}
            </th>
            <th onClick={() => handleSort('phone')}>
              Phone {getSortArrow('phone')}
            </th>
            <th onClick={() => handleSort('cartNumber')}>
              Cart Number {getSortArrow('cartNumber')}
            </th>
            <th onClick={() => handleSort('cartLocation')}>
              Cart Location {getSortArrow('cartLocation')}
            </th>
            <th onClick={() => handleSort('startTime')}>
              Start Time {getSortArrow('startTime')}
            </th>
            <th onClick={() => handleSort('endTime')}>
              End Time {getSortArrow('endTime')}
            </th>
            <th onClick={() => handleSort('aadhaar')}>
              Aadhaar No. {getSortArrow('aadhaar')}
            </th>
            <th>Aadhaar Doc</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentVendors.length > 0 ? (
            currentVendors.map(vendor => (
              <tr key={vendor.id}>
                <td>{vendor.id}</td>
                <td>{vendor.name}</td>
                <td>{vendor.email}</td>
                <td>{vendor.phone}</td>
                <td>{vendor.cartNumber}</td>
                <td>{`${vendor.societyName}, ${vendor.city}, ${vendor.state}, ${vendor.pinCode}`}</td>
                <td>{vendor.startTime}</td>
                <td>{vendor.endTime}</td>
                <td>{vendor.aadhaar}</td>
                <td>
                  {vendor.aadhaarFile ? (
                    <a href={vendor.aadhaarFile} download={`aadhaar_${vendor.id}.pdf`}>Download</a>
                  ) : (
                    'NA'
                  )}
                </td>
                <td>
                  <div className="status-container">
                    <select
                      value={vendor.status}
                      onChange={(e) => onStatusChange(vendor.id, e.target.value)}
                    >
                      <option value="Active">Active</option>
                      <option value="Pending">Pending</option>
                      <option value="Blocked">Blocked</option>
                    </select>
                    {statusChangeSuccess[vendor.id] && <span className="success-tick">✔</span>}
                  </div>
                </td>
                <td>
                  <button onClick={() => onEdit(vendor)}>Edit</button>
                  <button onClick={() => onDelete(vendor.id)}>Delete</button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="13">No vendors available.</td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="pagination">
        {Array.from({ length: Math.ceil(sortedVendors.length / vendorsPerPage) }, (_, i) => (
          <button
            key={i + 1}
            onClick={() => handlePageChange(i + 1)}
            className={currentPage === i + 1 ? 'active' : ''}
          >
            {i + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default VendorList;