import React, { createContext, useState, useEffect } from 'react';
import apiService from '../services/apiService';

export const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const sessionUser = localStorage.getItem('user');
    if (sessionUser) {
      setUser(JSON.parse(sessionUser));
      setIsLoggedIn(true);
    }
  }, []);

  const login = async (username, password) => {
    // TODO: Integrate with login API
    // Call the login API with the provided username and password
    // Set the isLoggedIn state to true if the login is successful
    // Set the user state to the logged-in user data
    // Handle any errors or authentication failures
    // Example API integration logic:
    // api.login(username, password)
    //   .then(response => {
    //     setIsLoggedIn(true);
    //     setUser(response.data.user);
    //   })
    //   .catch(error => {
    //     console.error('Login failed:', error);
    //   });

    try {
      const response = await apiService.login(username, password);
      setIsLoggedIn(true);
      setUser(response.data.user);
      localStorage.setItem('user', JSON.stringify(response.data.user));
      setError(null);
    } catch (error) {
      setError('Login failed: Invalid credentials');
    }
  };

  const logout = async () => {
    // TODO: Integrate with logout API
    // Call the logout API to invalidate the user session
    // Set the isLoggedIn state to false
    // Set the user state to null
    // Handle any errors or logout failures
    // Example API integration logic:
    // api.logout()
    //   .then(() => {
    //     setIsLoggedIn(false);
    //     setUser(null);
    //   })
    //   .catch(error => {
    //     console.error('Logout failed:', error);
    //   });
    try {
      await apiService.logout();
      setIsLoggedIn(false);
      setUser(null);
      localStorage.removeItem('user');
      setError(null);
    } catch (error) {
      setError('Logout failed');
    }
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, user, login, logout, error }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;