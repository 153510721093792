import React, { useState, useEffect } from "react";
import CustomerEmptyState from "../components/Customer/customer-empty-state"; // Reuse the empty state component
import CustomerList from "../components/Customer/CustomerList";
import apiCustomerService from "../services/customerService";

const CustomerManagementPage = () => {
  const [customers, setCustomers] = useState([]);
  const [filterTerm, setFilterTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [statusChangeSuccess, setStatusChangeSuccess] = useState({});

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    try {
      const customerData = await apiCustomerService.getCustomers();
      setCustomers(customerData);
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  };

  const handleFilterChange = (e) => {
    setFilterTerm(e.target.value);
  };

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
  };

  const handleSort = (field) => {
    const order = sortField === field && sortOrder === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortOrder(order);
  };

  const handleStatusChange = async (customerId, newStatus) => {
    try {
      await apiCustomerService.updateCustomerStatus(customerId, newStatus);
      setCustomers(customers.map(customer => 
        customer.id === customerId ? { ...customer, status: newStatus } : customer
      ));
      setStatusChangeSuccess({ ...statusChangeSuccess, [customerId]: true });
      setTimeout(() => {
        setStatusChangeSuccess({ ...statusChangeSuccess, [customerId]: false });
      }, 2000);
    } catch (error) {
      console.error("Error updating customer status:", error);
    }
  };

  const filteredCustomers = customers.filter((customer) =>
    (customer.name.toLowerCase().includes(filterTerm.toLowerCase()) ||
    customer.email.toLowerCase().includes(filterTerm.toLowerCase()) ||
    customer.city.toLowerCase().includes(filterTerm.toLowerCase()) ||
    customer.state.toLowerCase().includes(filterTerm.toLowerCase()) ||
    customer.societyName.toLowerCase().includes(filterTerm.toLowerCase()) ||
    customer.status.toLowerCase().includes(filterTerm.toLowerCase())) &&
    (statusFilter === "" || customer.status === statusFilter)
  );

  const sortedCustomers = [...filteredCustomers].sort((a, b) => {
    if (a[sortField] < b[sortField]) return sortOrder === "asc" ? -1 : 1;
    if (a[sortField] > b[sortField]) return sortOrder === "asc" ? 1 : -1;
    return 0;
  });

  return (
    <div>
      <div className="container-sub-header">
        <div className="left-buttons">
          <input
            type="text"
            className="filter-input"
            placeholder="Filter customers"
            value={filterTerm}
            onChange={handleFilterChange}
          />
          <select className="filter-input" value={statusFilter} onChange={handleStatusFilterChange}>
            <option value="">All Statuses</option>
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
            <option value="Pending">Pending</option>
            <option value="Blocked">Blocked</option>
          </select>
        </div>
      </div>

      <div className="customer-container">
        {customers.length === 0 ? (
          <CustomerEmptyState />
        ) : (
          <CustomerList
            customers={sortedCustomers}
            onSort={handleSort}
            sortField={sortField}
            sortOrder={sortOrder}
            onStatusChange={handleStatusChange}
            statusChangeSuccess={statusChangeSuccess}
          />
        )}
      </div>
    </div>
  );
};

export default CustomerManagementPage;