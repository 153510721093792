// import axios from 'axios';

// Utility function to make API requests
export const apiService = {
  getInventory: async () => {
    try {
      // Uncomment the following line to integrate with the actual API
      // const response = await axios.get('/api/inventory');
      
      // Dummy data for testing
      const response = {
        data: [
          { id: 1, name: 'Apple', quantity: 10, price: 100, category: 'Fruits' },
          { id: 2, name: 'Banana', quantity: 20, price: 200, category: 'Fruits' },
          { id: 3, name: 'Carrot', quantity: 30, price: 300, category: 'Vegetables' },
        ],
      };

      return response.data;
    } catch (error) {
      console.error('Error getting inventory:', error);
      throw error;
    }
  },

  addInventoryItem: async (item) => {
    try {
      // Uncomment the following line to integrate with the actual API
      // const response = await axios.post('/api/inventory', item);
      
      // Dummy data for testing
      const response = {
        data: { ...item, id: Date.now() },
      };

      return response.data;
    } catch (error) {
      console.error('Error adding inventory item:', error);
      throw error;
    }
  },

  updateInventoryItem: async (item) => {
    try {
      // Uncomment the following line to integrate with the actual API
      // const response = await axios.put(`/api/inventory/${item.id}`, item);
      
      // Dummy data for testing
      const response = {
        data: item,
      };

      return response.data;
    } catch (error) {
      console.error('Error updating inventory item:', error);
      throw error;
    }
  },

  deleteInventoryItem: async (itemId) => {
    try {
      // Uncomment the following line to integrate with the actual API
      // const response = await axios.delete(`/api/inventory/${itemId}`);
      
      // Dummy data for testing
      const response = {
        data: { id: itemId },
      };

      return response.data;
    } catch (error) {
      console.error('Error deleting inventory item:', error);
      throw error;
    }
  },

  // New function to perform login
  login: async (username, password) => {
    try {
      // Dummy data for testing
      if (username === 'admin' && password === 'password') {
        return { data: { user: { username: 'admin', token: 'dummy-token' } } };
      } else {
        throw new Error('Invalid credentials');
      }
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  },

  logout: async () => {
    try {
      // Dummy data for testing
      return { success: true };
    } catch (error) {
      console.error('Logout failed:', error);
      throw error;
    }
  },
};

export default apiService;