import React from 'react';
import './Dashboard.css';

const AnalyticsCard = ({ title, count, color, icons }) => {
  return (
    <div className="analytics-card">
      <div className="card-header">
        <span className="card-title">{title}</span>
        <div className="card-icon">
          <img src={icons} alt="icon" />
        </div>
      </div>
      <div className="card-body">
        <span className="count">{count}</span>
        <div className="color-box" style={{backgroundColor: color}}></div>
      </div>
    </div>
  );
};

const ProductStatus = () => {
  return (
    <div className="dashboard-container">      
      <div className="status-sections">
        <div className="status-card">
          <h4>Product Status</h4>
          <div className="status-content">
            <img src="https://placeholder.pics/svg/80x80" alt="Product Status Chart" />
            <div className="status-details">
              <div>
                <span>Not delivered</span>
                <span className="percentage red">10%</span>
              </div>
              <div>
                <span>Delivered</span>
                <span className="percentage yellow">90%</span>
              </div>
            </div>
          </div>
        </div>

        <div className="status-card">
          <h4>Returns</h4>
          <div className="status-content">
            <img src="https://placeholder.pics/svg/80x80" alt="Returns Chart" />
            <div className="status-details">
              <div>
                <span>Returns Ratio</span>
                <span className="percentage blue">30%</span>
              </div>
            </div>
          </div>
        </div>

        <div className="status-card">
          <h4>Inventory Stock</h4>
          <div className="status-content">
            <img src="https://placeholder.pics/svg/80x80" alt="Inventory Stock Chart" />
            <div className="status-details">
              <div>
                <span>Remaining Stock</span>
                <span className="percentage green">50%</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="orders-section">
        <h2>Repeat Orders</h2>
        <div className="order-chart">
          <div className="y-axis">
            {[50, 100, 150, 200, 250, 300].map(val => (
              <div key={val} className="y-label">{val}</div>
            ))}
          </div>
          <div className="bars">
            {['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].map((month, i) => (
              <div key={month} className="bar-container">
                <div className="bar" style={{height: `${i * 30 + 50}%`}}>
                  {(i + 1).toFixed(1)}
                </div>
                <div className="x-label">{month}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const Calendar = () => {
  const days = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  const dates = Array.from({length: 7}, (_, i) => 20 + i);

  return (
    <div className="calendar">
      <div className="calendar-header">
        <div className="month-selector">
          <span>October</span>
          <img src="./assets/icons/down-arrow-plain.png" alt="month" />
          <span>2024</span>
          <img src="./assets/icons/down-arrow-plain.png" alt="year" />
        </div>
        <div className="view-buttons">
          <button className="active">Month</button>
          <button>Week</button>
        </div>
      </div>
      <div className="calendar-grid">
        <div className="weekdays">
          {days.map(day => <div key={day}>{day}</div>)}
        </div>
        <div className="dates">
          {dates.map(date => (
            <div key={date} className={`date ${date === 20 ? 'current' : ''}`}>
              {date}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const OrderList = () => {
  const orders = Array(8).fill({
    id: '231',
    date: '2024-10-05',
    time: '12:30 PM',
    items: 5
  });

  return (
    <div className="order-list">
      {orders.map((order, i) => (
        <div key={i} className="order-item">
          <div className="order-details">
            <div>
              <span>Order ID: {order.id}</span>
              <span>{order.date} | {order.time}</span>
            </div>
            <div className="order-meta">
              <span>Total Item: {order.items}</span>
              <img src="./assets/icons/right-arrow-plain.png" alt="arrow" className='order-arrow' />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const Dashboard = () => {
  const analyticsData = [
    { title: 'Inventory Items', count: '200', color: '#ff0022', icon: './assets/icons/right-arrow-red.png' },
    { title: 'Vendors', count: '15', color: '#ffd700', icon: './assets/icons/right-arrow-yellow.png' },
    { title: 'Dummy', count: '3', color: '#4169e1', icon: './assets/icons/right-arrow-blue.png' },
    { title: 'Dummmy', count: '6', color: '#32cd32', icon: './assets/icons/right-arrow-green.png' }
  ];

  return (
    <div className="main-center-container">
      <div className="main-content">
        <div className='container-heading'>Vendors</div>
          <section className="analytics">              
            <section className="orders-section">
              <h2>Analytics</h2>
              <section className="status-sections-white">
                <div className="analytics-grid">
                  {analyticsData.map((item, index) => (
                    <AnalyticsCard 
                      key={index}
                      title={item.title}
                      count={item.count}
                      color={item.color}
                      icons={item.icon}
                    />
                  ))}
                </div>
              </section>
            </section>
          </section>

        <section className="status-section">
          <ProductStatus />
        </section>
      </div>
      
      <div className="sidebar right-sidebar">
        <Calendar />
        <OrderList />
      </div>
    </div>
  );
};

export default Dashboard;
