import React from 'react';
import './Orders-empty-state.css';
import emptyStateImage from '../../assets/emptystate/inventory-empty-state.png';

const OrderEmptyState = () => {
  return (
    <div className="empty-state">
      <img src={emptyStateImage} alt="Placeholder" />
      <h2 className="main-heading">Good Things Comes With Time</h2>
    </div>
  );
};

export default OrderEmptyState;
