import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import LoginPage from '../pages/LoginPage';
import DashboardPage from '../pages/DashboardPage';
import CustomerManagementPage from '../pages/CustomerManagementPage';
import InventoryManagementPage from '../pages/InventoryManagementPage';
import PriceManagementPage from '../pages/PriceManagementPage';
import ProtectedRoute from './ProtectedRoute';
import MainLayout from '../components/Layout/MainLayout';
import VendorManagementPage from '../pages/VendorManagementPage';
import OrderManagementPage from '../pages/OrderManagementPage';
import CartManagementPage from '../pages/CartManagementPage';
import AdminManagementPage from '../pages/AdminManagementPage';

const AppRoutes = () => {
  const { isLoggedIn } = useContext(AuthContext);
  return (    
      <Switch>
        <Route exact path="/">
          {isLoggedIn ? <Redirect to="/dashboard" /> : <LoginPage />}
        </Route>
        <ProtectedRoute
          path="/dashboard"
          component={(props) => (
            <MainLayout breadcrumbItems={['Home', 'Dashboard']}>
              <DashboardPage {...props} />
            </MainLayout>
          )}
        />
        <ProtectedRoute
          path="/carts"
          component={(props) => (
            <MainLayout breadcrumbItems={['Home', 'Carts']}>
              <CartManagementPage {...props} />
            </MainLayout>
          )}
        />
        <ProtectedRoute
          path="/customers"
          component={(props) => (
            <MainLayout breadcrumbItems={['Home', 'Customers']}>
              <CustomerManagementPage {...props} />
            </MainLayout>
          )}
        />
        <ProtectedRoute
          path="/vendors"
          component={(props) => (
            <MainLayout breadcrumbItems={['Home', 'Vendor']}>
              <VendorManagementPage {...props} />
            </MainLayout>
          )}
        />
        <ProtectedRoute
          path="/orders"
          component={(props) => (
            <MainLayout breadcrumbItems={['Home', 'Orders']}>
              <OrderManagementPage {...props} />
            </MainLayout>
          )}
        />
        <ProtectedRoute
          path="/inventory"
          component={(props) => (
            <MainLayout breadcrumbItems={['Home', 'Inventory']}>
              <InventoryManagementPage {...props} />
            </MainLayout>
          )}
        />
        <ProtectedRoute
          path="/inventory/products"
          component={(props) => (
            <MainLayout breadcrumbItems={['Home', 'Inventory', 'Product']}>
              <InventoryManagementPage {...props} />
            </MainLayout>
          )}
        />
        <ProtectedRoute
          path="/inventory/products/edit/:productId"
          component={(props) => (
            <MainLayout breadcrumbItems={['Home', 'Inventory', 'Product', 'Edit']}>
              <InventoryManagementPage {...props} />
            </MainLayout>
          )}
        />
        <ProtectedRoute
          path="/price/management"
          component={(props) => (
            <MainLayout breadcrumbItems={['Home', 'Price', 'Management']}>
              <PriceManagementPage {...props} />
            </MainLayout>
          )}
        />
        <ProtectedRoute
          path="/manage"
          component={(props) => (
            <MainLayout breadcrumbItems={['Home', 'Admin', 'Manage']}>
              <AdminManagementPage {...props} />
            </MainLayout>
          )}
        />
      </Switch>    
  );
};

export default AppRoutes;