import React from 'react';
import './OrderItemsPopup.css';

const OrderItemsPopup = ({ items, onClose }) => {
  const calculateTotal = () => {
    return items.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  return (
    <div className="order-items-popup">
      <div className="order-items-popup-content">
        <h3>Purchased Items</h3>
        <button className="close-button" onClick={onClose}>X</button>
        <table>
          <thead>
            <tr>
              <th>Product ID</th>
              <th>Item Name</th>
              <th>Quantity</th>
              <th>Price</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr key={index}>
                <td>{item.id}</td>
                <td>{item.name}</td>
                <td>{item.quantity}</td>
                <td>{item.price}</td>
                <td>{item.price * item.quantity}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="total">
          <strong>Total: </strong>${calculateTotal().toFixed(2)}
        </div>
      </div>
    </div>
  );
};

export default OrderItemsPopup;