import React, { useState } from 'react';
import './VendorForm.css'; 

const VendorForm = ({ onSubmit }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    aadhaar: '',
    aadhaarFile: null,
    cartNumber: `CART-${Date.now()}`,
    societyName: '',
    city: '',
    state: '',
    pinCode: '',
    startTime: '',
    endTime: '',
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [submissionError, setSubmissionError] = useState('');

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'aadhaarFile') {
      const file = files[0];
      if (file && file.size > 1048576) {
        setErrors({ ...errors, aadhaarFile: 'File size should be less than 1MB' });
      } else if (file && file.type !== 'application/pdf') {
        setErrors({ ...errors, aadhaarFile: 'File should be a PDF' });
      } else {
        setErrors({ ...errors, aadhaarFile: null });
        setFormData({ ...formData, aadhaarFile: file });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  
  const validate = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is invalid';
    }
    if (!formData.phone) newErrors.phone = 'Phone number is required';
    if (!formData.address) newErrors.address = 'Address is required';
    if (!formData.aadhaar) newErrors.aadhaar = 'Aadhaar number is required';
    if (!formData.aadhaarFile) {
      newErrors.aadhaarFile = 'Aadhaar document is required';
    } else if (formData.aadhaarFile.type !== 'application/pdf') {
      newErrors.aadhaarFile = 'Aadhaar document must be a PDF';
    } else if (formData.aadhaarFile.size > 1048576) {
      newErrors.aadhaarFile = 'Aadhaar document must be less than 1MB';
    }
    if (!formData.societyName) newErrors.societyName = 'Society Name is required';
    if (!formData.city) newErrors.city = 'City is required';
    if (!formData.state) newErrors.state = 'State is required';
    if (!formData.pinCode) newErrors.pinCode = 'Pin Code is required';
    if (!formData.startTime) newErrors.startTime = 'Start Time is required';
    if (!formData.endTime) newErrors.endTime = 'End Time is required';
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      setLoading(true);
      setSubmissionError('');
      try {
        await onSubmit(formData);
      } catch (error) {
        setSubmissionError('Failed to submit the form. Please try again.');
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <form className="vendor-form" onSubmit={handleSubmit}>
      <h2 className="form-title">Add Vendor</h2>
      <h3 className="section-title">Vendor Details</h3>
      <div className="form-row">
        <div className="form-group">
          <label htmlFor="vendorName">Vendor Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className={errors.name ? 'input-error' : ''}
          />
          {errors.name && <div className="error">{errors.name}</div>}
        </div>
        <div className="form-group">
          <label htmlFor="vendorEmail">Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className={errors.email ? 'input-error' : ''}
          />
          {errors.email && <div className="error">{errors.email}</div>}
        </div>
      </div>
      <div className="form-row">
        <div className="form-group">
          <label htmlFor="vendorPhone">Phone</label>
          <input
            type="text"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className={errors.phone ? 'input-error' : ''}
          />
          {errors.phone && <div className="error">{errors.phone}</div>}
        </div>
        <div className="form-group">
          <label htmlFor="vendorAddress">Address</label>
          <input
            type="text"
            name="address"
            value={formData.address}
            onChange={handleChange}
            className={errors.address ? 'input-error' : ''}
          />
          {errors.address && <div className="error">{errors.address}</div>}
        </div>
      </div>
      <div className="form-row">
        <div className="form-group">
          <label htmlFor="aadhaarNumber">Aadhaar Number</label>
          <input
            type="text"
            name="aadhaar"
            value={formData.aadhaar}
            onChange={handleChange}
            className={errors.aadhaar ? 'input-error' : ''}
          />
          {errors.aadhaar && <div className="error">{errors.aadhaar}</div>}
        </div>
        <div className="form-group">
          <label htmlFor="aadhaarFile">Aadhaar Document (PDF, less than 1MB)</label>
          <input
            type="file"
            name="aadhaarFile"
            accept="application/pdf"
            onChange={handleChange}
            className={errors.aadhaarFile ? 'input-error' : ''}
          />
          {errors.aadhaarFile && <div className="error">{errors.aadhaarFile}</div>}
        </div>
      </div>
      <h3 className="section-title">Cart Details</h3>
      <div className="form-row">
        <div className="form-group">
          <label htmlFor="cartNumber">Cart Number</label>
          <input
            type="text"
            name="cartNumber"
            value={formData.cartNumber}
            readOnly
          />
        </div>
        <div className="form-group">
          <label htmlFor="societyName">Society Name</label>
          <input
            type="text"
            name="societyName"
            value={formData.societyName}
            onChange={handleChange}
            className={errors.societyName ? 'input-error' : ''}
          />
          {errors.societyName && <div className="error">{errors.societyName}</div>}
        </div>
      </div>
      <div className="form-row">
        <div className="form-group">
          <label htmlFor="city">City</label>
          <input
            type="text"
            name="city"
            value={formData.city}
            onChange={handleChange}
            className={errors.city ? 'input-error' : ''}
          />
          {errors.city && <div className="error">{errors.city}</div>}
        </div>
        <div className="form-group">
          <label htmlFor="state">State</label>
          <input
            type="text"
            name="state"
            value={formData.state}
            onChange={handleChange}
            className={errors.state ? 'input-error' : ''}
          />
          {errors.state && <div className="error">{errors.state}</div>}
        </div>
        <div className="form-group">
          <label htmlFor="pinCode">Pin Code</label>
          <input
            type="text"
            name="pinCode"
            value={formData.pinCode}
            onChange={handleChange}
            className={errors.pinCode ? 'input-error' : ''}
          />
          {errors.pinCode && <div className="error">{errors.pinCode}</div>}
        </div>
      </div>
      <div className="form-row">
        <div className="form-group">
          <label htmlFor="startTime">Start Time</label>
          <input
            type="time"
            name="startTime"
            value={formData.startTime}
            onChange={handleChange}
            className={errors.startTime ? 'input-error' : ''}
          />
          {errors.startTime && <div className="error">{errors.startTime}</div>}
        </div>
        <div className="form-group">
          <label htmlFor="endTime">End Time</label>
          <input
            type="time"
            name="endTime"
            value={formData.endTime}
            onChange={handleChange}
            className={errors.endTime ? 'input-error' : ''}
          />
          {errors.endTime && <div className="error">{errors.endTime}</div>}
        </div>
      </div>
      <button type="submit" className="submit-button" disabled={loading}>
        {loading ? 'Submitting...' : 'Save'}
      </button>
      {loading && <div className="spinner"></div>}
      {submissionError && <div className="error">{submissionError}</div>}
    </form>
  );
};

export default VendorForm;