const vendorsData = [
  {
    id: 1,
    name: 'Vendor One',
    email: 'vendor1@example.com',
    phone: '1234567890',
    address: 'Address 1',
    aadhaar: '1234-5678-9123',
    aadhaarFile: null,
    cartNumber: 'CART-1633024800000',
    societyName: 'Society One',
    city: 'City One',
    state: 'State One',
    pinCode: '123456',
    startTime: '08:00',
    endTime: '18:00',
    status: "Active",
  },
  {
    id: 2,
    name: 'Vendor Two',
    email: 'vendor2@example.com',
    phone: '0987654321',
    address: 'Address 2',
    aadhaar: '9876-5432-1098',
    aadhaarFile: null,
    cartNumber: 'CART-1633028400000',
    societyName: 'Society Two',
    city: 'City Two',
    state: 'State Two',
    pinCode: '654321',
    startTime: '09:00',
    endTime: '19:00',
    status: "Pending",
  },
  {
    id: 3,
    name: 'Vendor three',
    email: 'vendor3@example.com',
    phone: '9898989898',
    address: 'Address 3',
    aadhaar: '1234-5432-5678',
    aadhaarFile: null,
    cartNumber: 'CART-16334678400000',
    societyName: 'Society Three',
    city: 'City Three',
    state: 'State Three',
    pinCode: '654321',
    startTime: '09:00',
    endTime: '19:00',
    status: "Blocked",
  },
];

export const apiVendorService = {
  getVendors: async () => {
    return new Promise((resolve) => {
      setTimeout(() => resolve({ data: vendorsData }), 500);
    });
  },

  addVendor: async (vendor) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (vendor.aadhaarFile) {
          const reader = new FileReader();
          reader.onload = () => {
            vendor.id = vendorsData.length + 1; // Assign a new ID
            vendor.aadhaarFile = reader.result; // Save file content as base64
            vendorsData.push(vendor);
            resolve({ data: vendor });
          };
          reader.onerror = () => reject(new Error('Failed to read file'));
          reader.readAsDataURL(vendor.aadhaarFile);
        } else {
          reject(new Error('Aadhaar document is required'));
        }
      }, 500);
    });
  },

  updateVendor: async (vendor) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const index = vendorsData.findIndex((v) => v.id === vendor.id);
        if (index > -1) {
          if (vendor.aadhaarFile) {
            const reader = new FileReader();
            reader.onload = () => {
              vendor.aadhaarFile = reader.result; // Save file content as base64
              vendorsData[index] = vendor;
              resolve({ data: vendor });
            };
            reader.onerror = () => reject(new Error('Failed to read file'));
            reader.readAsDataURL(vendor.aadhaarFile);
          } else {
            vendorsData[index] = vendor;
            resolve({ data: vendor });
          }
        } else {
          reject(new Error('Vendor not found'));
        }
      }, 500);
    });
  },

  deleteVendor: async (id) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const index = vendorsData.findIndex((v) => v.id === id);
        if (index > -1) {
          vendorsData.splice(index, 1);
        }
        resolve({ success: true });
      }, 500);
    });
  },

  updateVendorStatus: async (vendorId, newStatus) => {
    // Simulate an API call to update customer status
    const vendorIndex = vendorsData.findIndex(vendor => vendor.id === vendorId);
    if (vendorIndex !== -1) {
      vendorsData[vendorIndex].status = newStatus;
      return Promise.resolve();
    } else {
      return Promise.reject('Vendor not found');
    }
  },
};