import React, { useState } from 'react';
import './VendorOrderList.css';
import OrderItemsPopup from './OrderItemsPopup';

const VendorOrderList = ({ orders, onSort, sortField, sortOrder }) => {
  const [selectedOrderItems, setSelectedOrderItems] = useState([]);
  const [showItemsPopup, setShowItemsPopup] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const ordersPerPage = 5;

  const getSortArrow = (field) => {
    if (sortField === field) {
      return sortOrder === 'asc' ? '↑' : '↓';
    }
    return '';
  };

  const handleViewItems = (items) => {
    setSelectedOrderItems(items);
    setShowItemsPopup(true);
  };

  const handleClosePopup = () => {
    setShowItemsPopup(false);
  };

  // Pagination logic
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = orders.slice(indexOfFirstOrder, indexOfLastOrder);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="vendor-order-list">
      <table>
        <thead>
          <tr>
            <th onClick={() => onSort('id')}>Order ID {getSortArrow('id')}</th>
            <th onClick={() => onSort('vendorId')}>Vendor ID {getSortArrow('vendorId')}</th>
            <th onClick={() => onSort('vendor')}>Vendor {getSortArrow('vendor')}</th>
            <th onClick={() => onSort('date')}>Date {getSortArrow('date')}</th>
            <th onClick={() => onSort('total')}>Total {getSortArrow('total')}</th>
            <th onClick={() => onSort('status')}>Status {getSortArrow('status')}</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentOrders.length > 0 ? (
            currentOrders.map((order) => (
              <React.Fragment key={order.id}>
                <tr>
                  <td>{order.id}</td>
                  <td>{order.vendorId}</td>
                  <td>{order.vendor}</td>
                  <td>{order.date}</td>
                  <td>{order.total}</td>
                  <td>{order.status}</td>
                  <td>
                    <button className="action-button" onClick={() => handleViewItems(order.items)}>View Items</button>
                  </td>
                </tr>
              </React.Fragment>
            ))
          ) : (
            <tr>
              <td colSpan="7">No orders available</td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="pagination">
        {Array.from({ length: Math.ceil(orders.length / ordersPerPage) }, (_, i) => (
          <button
            key={i + 1}
            onClick={() => handlePageChange(i + 1)}
            className={currentPage === i + 1 ? 'active' : ''}
          >
            {i + 1}
          </button>
        ))}
      </div>
      {showItemsPopup && <OrderItemsPopup items={selectedOrderItems} onClose={handleClosePopup} />}
    </div>
  );
};

export default VendorOrderList;