import React from 'react';
import './Vendor-empty-state.css';
import emptyStateImage from '../../assets/emptystate/inventory-empty-state.png';

const VendorEmptyState = () => {
  return (
    <div className="empty-state">
      <img src={emptyStateImage} alt="Placeholder" />
      <h2 className="main-heading">Add Vendors To Start Business</h2>
      <button className="add-items-button">Start Adding Vendors</button>
    </div>
  );
};

export default VendorEmptyState;
