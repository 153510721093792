import axios from 'axios';

const products = [
  { id: 1, productName: 'Tomato', price: 10, description: 'Fresh tomatoes', measureUnit: 'Kgs', weight: 1, category: 'Vegetables', subCategory: 'vegetable Vegetables', quantity: 100, image: '/assets/product_image/tomato.jpg' },
  { id: 2, productName: 'Potato', price: 20, description: 'Fresh potatoes', measureUnit: 'Kgs', weight: 1, category: 'Vegetables', subCategory: 'Root Vegetables', quantity: 200, image: '/assets/product_image/potato.jpg' },
  { id: 3, productName: 'Carrot', price: 30, description: 'Fresh carrots', measureUnit: 'Kgs', weight: 1, category: 'Vegetables', subCategory: 'Root Vegetables', quantity: 300, image: '/assets/product_image/carrot.jpg' },
];

export const subCategories = {
  Fruits: [
    { id: 1, name: 'Exotic Fruit', description: "This is Exotic Fruits Description", img: "/assets/subcategory/exoticfruit.png" },
    { id: 2, name: 'Seasonal Fruit', description: "This is Seasonal Fruits Description", img: "/assets/subcategory/seasonalfruit.png" },
    { id: 3, name: 'Offer Fruit', description: "This is Offer Fruits Description", img: "/assets/subcategory/offerfruit.png" },
    { id: 4, name: 'High Demand Fruit', description: "This is High Demand Fruits Description", img: "/assets/subcategory/highdemandfruit.png" },
  ],
  Vegetables: [
    { id: 1, name: 'Exotic Vegetables', description: "This is Exotic Vegetables Description", img: "/assets/subcategory/exoticvegetable.png" },
    { id: 2, name: 'Seasonal Vegetables', description: "This is Seasonal Vegetables Description", img: "/assets/subcategory/seasonalvegetable.png" },
    { id: 3, name: 'Offer Vegetables', description: "This is Offer Vegetables Description", img: "/assets/subcategory/offervegetable.png" },
    { id: 4, name: 'High Demand Vegetables', description: "This is High Demand Vegetables Description", img: "/assets/subcategory/highdemandvegetable.png" },
  ],
};

export const getProducts = async () => {
  // TODO: Replace with actual API call
  return Promise.resolve(products);
};

export const getProductById = async (id) => {
  // TODO: Replace with actual API call
  const product = products.find(p => p.id === parseInt(id));
  if (product) {
    return Promise.resolve(product);
  }
  return Promise.reject('Product not found');
};

export const addProduct = async (product) => {
  // TODO: Replace with actual API call
  const newProduct = { ...product, id: products.length + 1 };

  if (product.image) {
    const imagePath = `/assets/product_image/${newProduct.id}-${product.image.name}`;
    newProduct.image = imagePath;

    // Simulate saving the image to the public/assets/product_image folder
    const reader = new FileReader();
    reader.onload = () => {
      const imageData = reader.result;
      // Save imageData to the server or local storage
      // For now, we just log it to the console
      console.log(`Saving image to ${imagePath}`, imageData);
    };
    reader.readAsDataURL(product.image);
  }

  products.push(newProduct);
  return Promise.resolve(newProduct);
};

export const updateProduct = async (product) => {
  // TODO: Replace with actual API call
  const index = products.findIndex(p => p.id === product.id);
  if (index !== -1) {
    products[index] = product;
    return Promise.resolve(product);
  }
  return Promise.reject('Product not found');
};

export const deleteProduct = async (productId) => {
  // TODO: Replace with actual API call
  const index = products.findIndex(p => p.id === productId);
  if (index !== -1) {
    products.splice(index, 1);
    return Promise.resolve();
  }
  return Promise.reject('Product not found');
};


// Import Product using CSV
export const uploadCSVProducts = async (products) => {
  try {
    const response = await axios.post('/api/products/import', { products });
    return response.data;
  } catch (error) {
    throw new Error('Failed to upload products');
  }
};