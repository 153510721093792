import React, { useState, useEffect } from "react";
import CustomerOrderList from "../components/Order/CustomerOrderList";
import VendorOrderList from "../components/Order/VendorOrderList";
import WarehouseSummary from "../components/Order/WarehouseSummary";
import apiOrderService from "../services/orderService";
import OrderEmptyState from "../components/Order/Orders-empty-state";
import '../styles/OrderManagementPage.css';

const OrderManagementPage = () => {
  const [orders, setOrders] = useState([]);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [filter, setFilter] = useState("");
  const [selectedOrderType, setSelectedOrderType] = useState("vendor");
  const [sortField, setSortField] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  const [selectedTab, setSelectedTab] = useState('order');
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);

  useEffect(() => {
    fetchOrders();
  }, [selectedOrderType]);

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage("");
        setMessageType("");
      }, 3000); // Hide message after 3 seconds

      return () => clearTimeout(timer); // Clear timeout if component unmounts or message changes
    }
  }, [message]);

  const fetchOrders = async () => {
    try {
      const response = selectedOrderType === "vendor"
        ? await apiOrderService.getVendorOrders()
        : await apiOrderService.getCustomerOrders();
      setOrders(response);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const handleOrderTypeChange = (type) => {
    setSelectedOrderType(type);
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const handleSort = (field) => {
    const order = sortField === field && sortOrder === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortOrder(order);
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const filteredOrders = orders.filter((order) =>
    selectedOrderType === "vendor"
      ? (order.vendor && order.vendor.toLowerCase().includes(filter.toLowerCase())) ||
        (order.date && order.date.includes(filter)) ||
        (order.total && order.total.toString().includes(filter)) ||
        (order.status && order.status.toLowerCase().includes(filter.toLowerCase())) ||
        (order.id && order.id.toString().includes(filter)) ||
        (order.vendorId && order.vendorId.toString().includes(filter))
      : (order.customer && order.customer.toLowerCase().includes(filter.toLowerCase())) ||
        (order.date && order.date.includes(filter)) ||
        (order.total && order.total.toString().includes(filter)) ||
        (order.status && order.status.toLowerCase().includes(filter.toLowerCase())) ||
        (order.shippingAddress && order.shippingAddress.toLowerCase().includes(filter.toLowerCase())) ||
        (order.id && order.id.toString().includes(filter)) ||
        (order.customerId && order.customerId.toString().includes(filter))
  );

  const sortedOrders = [...filteredOrders].sort((a, b) => {
    if (a[sortField] < b[sortField]) return sortOrder === "asc" ? -1 : 1;
    if (a[sortField] > b[sortField]) return sortOrder === "asc" ? 1 : -1;
    return 0;
  });

  return (
    <div>
      <div className="container-sub-header">
        <div className="left-buttons">
          <button
            className={`action-button ${selectedOrderType === "vendor" ? "active" : ""}`}
            onClick={() => handleOrderTypeChange("vendor")}
          >
            Vendor Orders
          </button>
          <button
            className={`action-button ${selectedOrderType === "customer" ? "active" : ""}`}
            onClick={() => handleOrderTypeChange("customer")}
          >
            Customer Orders
          </button>          
        </div>
        <div className="right-buttons">
          <input
            type="text"
            placeholder="Search by name"
            value={filter}
            onChange={handleFilterChange}
            className="filter-input"
          />
        </div>
      </div>

      <div className="order-container">
        {orders.length === 0 ? (
          <OrderEmptyState />
        ) : selectedOrderType === "customer" ? (
          <CustomerOrderList
            orders={sortedOrders}
            onSort={handleSort}
            sortField={sortField}
            sortOrder={sortOrder}
          />
        ) : (
          <div>
            <div className="tabs">
              <button className={`action-button ${selectedTab === 'order' ? 'active' : ''}`} onClick={() => handleTabChange('order')} >Order</button>
              <button className={`action-button ${selectedTab === 'summary' ? 'active' : ''}`} onClick={() => handleTabChange('summary')}>Warehouse Summary</button>
            </div>
            {selectedTab === 'order' ? (
              <VendorOrderList
                orders={sortedOrders}
                onSort={handleSort}
                sortField={sortField}
                sortOrder={sortOrder}
              />
            ) : (
              <WarehouseSummary orders={orders} selectedDate={selectedDate} onDateChange={handleDateChange} />
            )}
          </div>
        )}
        {message && <div className={`message ${messageType}`}>{message}</div>}
      </div>
    </div>
  );
};

export default OrderManagementPage;