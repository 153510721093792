import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import Login from '../components/Auth/Login';

const LoginPage = () => {
  const history = useHistory();
  const { login, error, isLoggedIn } = useContext(AuthContext);

  const handleLogin = async(username, password) => {
    // TODO: Implement login logic
    // Call the login API endpoint with the provided credentials
    // Uncomment the code below when the API integration is ready

    // api.login(username, password)
    //   .then(response => {
    //     // Handle successful login
    //     // Save the authentication token to local storage or context
    //     // Redirect to the dashboard page
    //     history.push('/dashboard');
    //   })
    //   .catch(error => {
    //     // Handle login error
    //     // Display error message to the user
    //   });

    await login(username, password);
    if (!error && isLoggedIn) {
      history.push('/dashboard');
    }
  };

  return (
    <div>
      <Login onLogin={handleLogin} error={error} />
    </div>
  );
};

export default LoginPage;