import React, { useState, useEffect } from 'react';
import { Switch, Route, useRouteMatch, useLocation } from 'react-router-dom';
import Papa from 'papaparse';
import ProductEmptyState from '../components/Inventory/Products-empty-state';
import ProductListing from '../components/Inventory/ProductListing';
import { subCategories, getProducts, addProduct, updateProduct, deleteProduct} from '../services/productService';
import AddProduct from '../components/Inventory/AddProduct';
import EditProduct from '../components/Inventory/EditProduct';
import ImportProduct from '../components/Inventory/ImportProduct';
import AddSubCategoryForm from '../components/Inventory/AddSubCategoryForm';
import '../styles/InventoryManagementPage.css';

const InventoryManagementPage = () => {
  const { path } = useRouteMatch();
  const location = useLocation();
  // const history = useHistory();
  const [products, setProducts] = useState([]);
  const [isAddPopupOpen, setIsAddPopupOpen] = useState(false);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [isAddSubCategoryPopupOpen, setIsAddSubCategoryPopupOpen] = useState(false);
  const [isImportPopupOpen, setIsImportPopupOpen] = useState(false);
  const [editingProduct, setEditingProduct] = useState(null);
  const [editingSubCategory, setEditingSubCategory] = useState(null);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('Products');
  const [selectedTab, setSelectedTab] = useState('Fruits');

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath.includes('/inventory/products')) {
      setSelectedCategory('Products');
    } else if (currentPath.includes('/inventory/categories')) {
      setSelectedCategory('Categories');
    }
  }, [location.pathname]);

  const fetchProducts = async () => {
    try {
      const productData = await getProducts();
      setProducts(productData);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const handleAddProduct = async (product) => {
    try {
      const response = await addProduct(product);
      setProducts([...products, response]);
      setMessage('Product added successfully!');
      setMessageType('success');
      setIsAddPopupOpen(false); // Close the popup after successful submission
    } catch (error) {
      setMessage('Failed to add product.');
      setMessageType('error');
    }
  };

  const handleUpdateProduct = async (updatedProduct) => {
    try {
      await updateProduct(updatedProduct);
      // Update the product list with the modified product
      const updatedProducts = products.map((product) =>
        product.id === updatedProduct.id ? updatedProduct : product
      );
      setProducts(updatedProducts);
      setMessage('Product updated successfully!');
      setMessageType('success');
      setIsEditPopupOpen(false); // Close the edit popup
    } catch (error) {
      setMessage('Failed to update product.');
      setMessageType('error');
    }
  };

  const handleDeleteProduct = async (productId) => {
    try {
      await deleteProduct(productId);
      // Remove the product from the list
      setProducts(products.filter((product) => product.id !== productId));
      setMessage('Product deleted successfully!');
      setMessageType('success');
    } catch (error) {
      setMessage('Failed to delete product.');
      setMessageType('error');
    }
  };

  const handleExportCSV = () => {
    const csv = Papa.unparse(products);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'products.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleImportCSV = () => {
    setIsImportPopupOpen(true); // Open the import CSV pop-up
  };

  const closeImportPopup = () => {
    setIsImportPopupOpen(false); // Close the pop-up
  };

  const handleSaveSubCategory = (data) => {
    const { selectedCategory, subCategoryName, description, image } = data;
    const categorySubCategories = subCategories[selectedCategory];
    const newSubCategory = {
      id: editingSubCategory ? editingSubCategory.subCategory.id : categorySubCategories.length ? Math.max(...categorySubCategories.map(subCat => subCat.id)) + 1 : 1,
      name: subCategoryName,
      description,
      img: image ? URL.createObjectURL(image) : null,
    };

    if (editingSubCategory) {
      // Update existing sub-category
      const index = categorySubCategories.findIndex(subCat => subCat.id === editingSubCategory.subCategory.id);
      if (index !== -1) {
        categorySubCategories[index] = newSubCategory;
      }
    } else {
      // Add new sub-category
      categorySubCategories.push(newSubCategory);
    }

    setMessage(editingSubCategory ? 'Sub-category updated successfully!' : 'Sub-category added successfully!');
    setMessageType('success');
    setIsAddSubCategoryPopupOpen(false); // Close the popup after successful submission
  };

  const handleCancelSubCategory = () => {
    setIsAddSubCategoryPopupOpen(false); // Close the popup
  };

  const handleAddSubCategory = () => {
    setEditingSubCategory(null); // Reset editing sub-category
    setIsAddSubCategoryPopupOpen(true); // Open the add sub-category popup
  };

  const handleEditSubCategory = (subCategory, category) => {
    setEditingSubCategory({ subCategory, category });
    setIsAddSubCategoryPopupOpen(true); // Open the add sub-category popup with filled information
  };

  const handleDeleteSubCategory = (subCategoryId, category) => {
    if (window.confirm('Are you sure you want to delete this sub-category?')) {
      const categorySubCategories = subCategories[category];
      const updatedSubCategories = categorySubCategories.filter(subCat => subCat.id !== subCategoryId);
      subCategories[category] = updatedSubCategories;
      setMessage('Sub-category deleted successfully!');
      setMessageType('success');
    }
  };

  const handleEditProduct = (product) => {
    setEditingProduct(product);
    setIsEditPopupOpen(true);
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  return (
    <div>
      <div className="container-sub-header">
        {/* <div className="left-buttons">
            <input type="text" className="filter-input" placeholder="Filter Products" value=""/>
        </div> */}
        <div className="right-buttons">
          {selectedCategory === 'Products' ? (
            <>
              <button className="action-button" onClick={handleExportCSV}>Export</button>
              <button className="action-button" onClick={handleImportCSV}>Import from CSV</button>
              <button className="action-button" onClick={() => setIsAddPopupOpen(true)}>Add Product</button>
            </>
          ) : (
            <button className="action-button" onClick={handleAddSubCategory}>Add Sub-Category</button>
            // <button className="action-button" onClick={handleAddSubCategory}>Add Category</button>
          )}
        </div>
        {isImportPopupOpen && <ImportProduct onClose={closeImportPopup} />}
      </div>

      <div className="products-container">
        <Switch>
          <Route path={`${path}/products`}>
            {products.length === 0 ? ( 
              <ProductEmptyState />
            ) : (
              <ProductListing
                products={products}
                onDelete={handleDeleteProduct} // Pass delete handler
                onEdit={handleEditProduct} // Pass edit handler
              />
            )}
          </Route>
          <Route path={`${path}/categories`}>
            <div className="tabs">
              <button className={`action-button ${selectedTab === 'Fruits' ? 'active' : ''}`} onClick={() => handleTabChange('Fruits')}>Fruits</button>
              <button className={`action-button ${selectedTab === 'Vegetables' ? 'active' : ''}`} onClick={() => handleTabChange('Vegetables')}>Vegetables</button>
            </div>
            <div className="sub-category-list">
              {subCategories[selectedTab].map((subCategory, index) => (
                <div key={index} className="sub-category-item card">
                  <img src={subCategory.img} alt={subCategory.name} />
                  <div className="sub-category-details">
                    <h3>{subCategory.name}</h3>
                    <p>{subCategory.description}</p>
                    <button className="action-button" onClick={() => handleEditSubCategory(subCategory, selectedTab)}>Edit</button>
                    <button className="action-button delete-button" onClick={() => handleDeleteSubCategory(subCategory.id, selectedTab)}>Delete</button>
                  </div>
                </div>
              ))}
            </div>
          </Route>
        </Switch>
      </div>

      {isAddPopupOpen && (
        <AddProduct
          onClose={() => setIsAddPopupOpen(false)}
          onSubmit={handleAddProduct}
        />
      )}

      {isEditPopupOpen && editingProduct && (
        <EditProduct
          onClose={() => setIsEditPopupOpen(false)}
          onSubmit={handleUpdateProduct}
          initialData={editingProduct}
        />
      )}

      {isAddSubCategoryPopupOpen && (
        <div className="popup-overlay">
          <div className="popup-container">
            <AddSubCategoryForm
              onSave={handleSaveSubCategory}
              onCancel={handleCancelSubCategory}
              initialData={editingSubCategory} // Pass the editing sub-category data
            />
          </div>
        </div>
      )}

      {message && <div className={`message ${messageType}`}>{message}</div>}
    </div>
  );
};

export default InventoryManagementPage;