import React, { useState, useContext, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import './Sidebar.css';
import { FaHome, FaBox,FaProductHunt, FaArrowRight, FaArrowLeft, FaCaretDown, FaCaretRight, FaCartArrowDown , FaUsers, FaDollarSign, FaSignOutAlt, FaCogs  } from 'react-icons/fa';
import { MdInventory, MdCategory } from "react-icons/md";
import logo from '../../assets/logo.png';
import logo_only from '../../assets/logo_only.png';
import { AuthContext } from '../../context/AuthContext';

const Sidebar = () => {
  const { logout } = useContext(AuthContext);
  const [collapsed, setCollapsed] = useState(false);
  const [inventoryOpen, setInventoryOpen] = useState(false);
  const [cartOpen, setCartOpen] = useState(false);
  const [orderOpen, setOrderOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith('/inventory')) {
      setInventoryOpen(true);
    }
    if (location.pathname.startsWith('/cart')) {
      setCartOpen(true);
    }
    if (location.pathname.startsWith('/order')) {
      setOrderOpen(true);
    }
  }, [location.pathname]);

  const handleToggle = () => {
    setCollapsed(!collapsed);
  };
  
  const toggleInventory = () => {
    setInventoryOpen(!inventoryOpen);
  };
  
  // const toggleCart = () => {
  //   setCartOpen(!cartOpen);
  // };

  // const toggleOrder = () => {
  //   setOrderOpen(!orderOpen);
  // };

  const handleLogout = async () => {
    await logout();
    history.push('/');
  };

  const isActive = (path) => location.pathname.startsWith(path);

  return (
    <div className={`sidebar ${collapsed ? 'collapsed' : ''}`}>
      <div className="logo-section">
        <img src={collapsed ? logo_only : logo} alt="Logo" className="logo" />
        <button className="collapse-button" onClick={handleToggle}>
          {collapsed ? <FaArrowRight /> : <FaArrowLeft />}
        </button>
      </div>
      <nav className="menu">
        <ul>
          <li title="Dashboard">
            <Link to="/dashboard" className={`menu-item ${isActive('/dashboard') ? 'active' : ''}`}>
              <FaHome className="icon" />
              {!collapsed && <span className="menu-text">Dashboard</span>}
            </Link>
          </li>    
          <li title="Cart">
            <Link to="/carts" className={`menu-item ${isActive('/carts') ? 'active' : ''}`}>
              <FaHome className="icon" />
              {!collapsed && <span className="menu-text">Cart</span>}
            </Link>
          </li>         
          <li title="Inventory">
            <div className={`menu-item ${isActive('/inventory') ? 'active-parent' : ''}`} onClick={toggleInventory}>
              <MdInventory className="icon" />
              {!collapsed && <span className="menu-text">Inventory</span>}
              {!collapsed && (inventoryOpen ? <FaCaretDown className="submenu-icon" /> : <FaCaretRight className="submenu-icon" />)}
            </div>
            {!collapsed && inventoryOpen && (
              <ul className="submenu">
                <li>
                  <Link to="/inventory/products" className={`submenu-item ${isActive('/inventory/products') ? 'active' : ''}`}>
                    <FaProductHunt className="icon" />
                    <span className="menu-text">Products</span>
                  </Link>
                </li>
                <li>
                  <Link to="/inventory/categories" className={`submenu-item ${isActive('/inventory/categories') ? 'active' : ''}`}>
                    <MdCategory className="icon" />
                    <span className="menu-text">Categories</span>
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li title="Vendor">
            <Link to="/vendors" className={`menu-item ${isActive('/vendors') ? 'active' : ''}`}>
              <FaBox className="icon" />
              {!collapsed && <span className="menu-text">Vendors</span>}
            </Link>
          </li>
          <li title="Customers">
            <Link to="/customers" className={`menu-item ${isActive('/customers') ? 'active' : ''}`}>
              <FaUsers className="icon" />
              {!collapsed && <span className="menu-text">Customers</span>}
            </Link>
          </li>
          <li title="Orders">
            <Link to="/orders" className={`menu-item ${isActive('/orders') ? 'active' : ''}`}>
              <FaCartArrowDown  className="icon" />
              {!collapsed && <span className="menu-text">Orders</span>}
            </Link>
          </li>
          <li title="Pricing">
            <Link to="/price/management" className={`menu-item ${isActive('/price/management') ? 'active' : ''}`}>
              <FaDollarSign className="icon" />
              {!collapsed && <span className="menu-text">Pricing</span>}
            </Link>
          </li>
          <li title="Manage">
            <Link to="/manage" className={`menu-item ${isActive('/manage') ? 'active' : ''}`}>
              <FaCogs className="icon" />
              {!collapsed && <span className="menu-text">Manage</span>}
            </Link>
          </li>
          <li title="Logout">
            <Link to="#" onClick={handleLogout} className="menu-item">
              <FaSignOutAlt className="icon" />
              {!collapsed && <span className="menu-text">Logout</span>}
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;