import React from 'react';
import './Header.css';

const Header = () => {
  return (
    <header className="header">
      <div className="header-content">
        <div className="header-right">
          <img src={`${process.env.PUBLIC_URL}/assets/icons/notification.png`} alt="Notifications" className="notification-icon" />
          <img src={`${process.env.PUBLIC_URL}/assets/icons/avatar.png`} alt="User Avatar" className="avatar-icon" />
        </div>
      </div>
    </header>
  );
};

export default Header;
