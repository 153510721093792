import React, { useState, useEffect } from "react";
import "./CustomerList.css";

const CustomerList = ({ customers, onSort, sortField, sortOrder, onStatusChange, statusChangeSuccess }) => {
  const [sortedCustomers, setSortedCustomers] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'ascending' });
  const [currentPage, setCurrentPage] = useState(1);
  const [customersPerPage] = useState(10);

  useEffect(() => {
    let sortedData = [...customers];

    // Sorting logic
    if (sortConfig !== null) {
      sortedData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }

    setSortedCustomers(sortedData);
  }, [customers, sortConfig]);

  // Pagination logic
  const indexOfLastVendor = currentPage * customersPerPage;
  const indexOfFirstVendor = indexOfLastVendor - customersPerPage;
  const currentCustomers = sortedCustomers.slice(indexOfFirstVendor, indexOfLastVendor);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getSortArrow = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? '↑' : '↓';
    }
    return '';
  };

  return (
    <div className="customer-list">
      <table>
        <thead>
          <tr>
            <th onClick={() => handleSort("id")}>ID {getSortArrow("id")}</th>
            <th onClick={() => handleSort("name")}>Name {getSortArrow("name")}</th>
            <th onClick={() => handleSort("email")}>Email {getSortArrow("email")}</th>
            <th onClick={() => handleSort("city")}>City {getSortArrow("city")}</th>
            <th onClick={() => handleSort("state")}>State {getSortArrow("state")}</th>
            <th onClick={() => handleSort("societyName")}>Society {getSortArrow("societyName")}</th>
            <th onClick={() => handleSort("status")}>Status {getSortArrow("status")}</th>
          </tr>
        </thead>
        <tbody>
          {currentCustomers.length > 0 ? (
            currentCustomers.map((customer) => (
              <tr key={customer.id}>
                <td>{customer.id}</td>
                <td>{customer.name}</td>
                <td>{customer.email}</td>
                <td>{customer.city}</td>
                <td>{customer.state}</td>
                <td>{customer.societyName}</td>
                <td>
                  <div className="status-container">
                    <select
                      value={customer.status}
                      onChange={(e) => onStatusChange(customer.id, e.target.value)}
                    >
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                      <option value="Pending">Pending</option>
                      <option value="Blocked">Blocked</option>
                    </select>
                    {statusChangeSuccess[customer.id] && <span className="success-tick">✔</span>}
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7">No customers available</td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="pagination">
        {Array.from({ length: Math.ceil(sortedCustomers.length / customersPerPage) }, (_, i) => (
          <button
            key={i + 1}
            onClick={() => handlePageChange(i + 1)}
            className={currentPage === i + 1 ? 'active' : ''}
          >
            {i + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default CustomerList;