import React, { useState, useEffect } from 'react';
import './AddSubCategoryForm.css';
import previewImage from '../../assets/inventory/sub-category-frame.png';

const AddSubCategoryForm = ({ onSave, onCancel, initialData }) => {
  const [subCategoryName, setSubCategoryName] = useState('');
  const [description, setDescription] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('Fruits');
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  useEffect(() => {
    if (initialData) {
      setSubCategoryName(initialData.subCategory.name);
      setDescription(initialData.subCategory.description);
      setSelectedCategory(initialData.category);
      setImagePreview(initialData.subCategory.img);
    }
  }, [initialData]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onload = () => setImagePreview(reader.result);
      reader.readAsDataURL(file);
    }
  };

  const handleSave = () => {
    if (!subCategoryName || !description) {
      alert('Please fill all required fields.');
      return;
    }
    const formData = {
      subCategoryName,
      description,
      selectedCategory,
      image,
    };
    onSave(formData);
  };

  return (
    <div className="add-sub-category-form">
      <h2 className="form-title-subcategory">Add Sub Category</h2>
      <div className="form-content">
        <div className="form-left">
          <div className="form-group-subcategory">
            <label>Enter Name of Sub Category</label>
            <input
              type="text"
              placeholder="Ex - Seasonal, Non - Seasonal"
              value={subCategoryName}
              onChange={(e) => setSubCategoryName(e.target.value)}
              required
            />
          </div>

          <div className="form-group-subcategory">
            <label>Description</label>
            <textarea
              placeholder="Write description about the product"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </div>

          <div className="form-group-subcategory">
            <label>Select Category</label>
            <div className="radio-group">
              <label>
                <input
                  type="radio"
                  name="category"
                  value="Fruits"
                  checked={selectedCategory === 'Fruits'}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                />
                Fruits
              </label>
              <label>
                <input
                  type="radio"
                  name="category"
                  value="Vegetables"
                  checked={selectedCategory === 'Vegetables'}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                />
                Vegetables
              </label>
            </div>
          </div>
        </div>

        <div className="form-right">
          <div className="image-upload" onClick={() => document.getElementById('imageUpload').click()}>
            <div className="image-preview-container">
              {imagePreview ? (
                <img src={imagePreview} alt="Preview" className="image-preview" />
              ) : (
                <div className="placeholder1">
                  <img src={previewImage} alt="Camera Icon" className="camera-icon" />
                  <span>+</span>
                  <p>Add</p>
                </div>
              )}
            </div>
            <input id="imageUpload" type="file" accept="image/*" onChange={handleImageChange} />
          </div>
          <label className="add-image-label">Add Image</label>
        </div>
      </div>

      <div className="form-actions">
        <button className="action-button" onClick={onCancel}>
          Cancel
        </button>
        <button className="action-button save" onClick={handleSave}>
          Save
        </button>
      </div>
    </div>
  );
};

export default AddSubCategoryForm;