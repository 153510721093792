import React from 'react';
import './Products-empty-state.css';
import emptyStateImage from '../../assets/emptystate/inventory-empty-state.png';

const ProductEmptyState = () => {
  return (
    <div className="empty-state">
      <img src={emptyStateImage} alt="Placeholder" />
      <h2 className="main-heading">Add items to your inventory</h2>
      <button className="add-items-button">Start Adding Items</button>
    </div>
  );
};

export default ProductEmptyState;
