const customers = [
  {
    id: 1,
    name: "John Doe",
    email: "john.doe@example.com",
    city: "New York",
    state: "NY",
    societyName: "Green Valley Society",
    status: "Active",
  },
  {
    id: 2,
    name: "Jane Smith",
    email: "jane.smith@example.com",
    city: "Los Angeles",
    state: "CA",
    societyName: "Sunshine Apartments",
    status: "Pending",
  },
  {
    id: 3,
    name: "Alice Johnson",
    email: "alice.johnson@example.com",
    city: "Chicago",
    state: "IL",
    societyName: "Maple Residency",
    status: "Blocked",
  },
  // Add more dummy customers as needed
];

const apiCustomerService = {
  getCustomers: async () => {
    // Simulate an API call
    return Promise.resolve(customers);
  },
  updateCustomerStatus: async (customerId, newStatus) => {
    // Simulate an API call to update customer status
    const customerIndex = customers.findIndex(customer => customer.id === customerId);
    if (customerIndex !== -1) {
      customers[customerIndex].status = newStatus;
      return Promise.resolve();
    } else {
      return Promise.reject('Customer not found');
    }
  },
  // Other service methods...
};

export default apiCustomerService;