import React, { useState, useEffect } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import VendorEmptyState from "../components/Vendor/Vendor-empty-state"; // Reuse the empty state component
import VendorList from "../components/Vendor/VendorList";
import VendorForm from "../components/Vendor/VendorForm";
import EditVendorForm from "../components/Vendor/EditVendorForm";
import { apiVendorService } from "../services/vendorService";

const VendorManagementPage = () => {
  const { path } = useRouteMatch();
  const [vendors, setVendors] = useState([]);
  const [isAddFormVisible, setIsAddFormVisible] = useState(false);
  const [isEditFormVisible, setIsEditFormVisible] = useState(false);
  const [editingVendor, setEditingVendor] = useState(null);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [filter, setFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [statusChangeSuccess, setStatusChangeSuccess] = useState({});

  useEffect(() => {
    fetchVendors();
  }, []);

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage("");
        setMessageType("");
      }, 3000); // Hide message after 3 seconds

      return () => clearTimeout(timer); // Clear timeout if component unmounts or message changes
    }
  }, [message]);

  const fetchVendors = async () => {
    try {
      const response = await apiVendorService.getVendors();
      setVendors(response.data);
    } catch (error) {
      console.error("Error fetching vendors:", error);
    }
  };

  const handleAddVendor = async (vendor) => {
    try {
      const response = await apiVendorService.addVendor(vendor);
      setVendors([...vendors, response.data]);
      setMessage("Vendor added successfully!");
      setMessageType("success");
      setIsAddFormVisible(false);
    } catch (error) {
      setMessage("Failed to add vendor.");
      setMessageType("error");
    }
  };

  const handleEditVendor = (vendor) => {
    setEditingVendor(vendor);
    setIsEditFormVisible(true);
  };

  const handleUpdateVendor = async (updatedVendor) => {
    try {
      const response = await apiVendorService.updateVendor(updatedVendor);
      setVendors(vendors.map(vendor => vendor.id === updatedVendor.id ? response.data : vendor));
      setMessage("Vendor updated successfully!");
      setMessageType("success");
      setIsEditFormVisible(false);
    } catch (error) {
      setMessage("Failed to update vendor.");
      setMessageType("error");
    }
  };

  const handleDeleteVendor = async (vendorId) => {
    if (window.confirm("Are you sure you want to delete this vendor?")) {
      try {
        await apiVendorService.deleteVendor(vendorId);
        setVendors(vendors.filter(vendor => vendor.id !== vendorId));
        setMessage("Vendor deleted successfully!");
        setMessageType("success");
      } catch (error) {
        setMessage("Failed to delete vendor.");
        setMessageType("error");
      }
    }
  };

  const handleViewVendors = () => {
    setIsAddFormVisible(false);
    setIsEditFormVisible(false);
  };

  const closeEditForm = () => {
    setIsEditFormVisible(false);
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
  };

  const handleStatusChange = async (vendorId, newStatus) => {
    try {
      await apiVendorService.updateVendorStatus(vendorId, newStatus);
      setVendors(vendors.map(vendor => 
        vendor.id === vendorId ? { ...vendor, status: newStatus } : vendor
      ));
      setStatusChangeSuccess({ ...statusChangeSuccess, [vendorId]: true });
      setTimeout(() => {
        setStatusChangeSuccess({ ...statusChangeSuccess, [vendorId]: false });
      }, 2000);
    } catch (error) {
      console.error("Error updating vendor status:", error);
    }
  };

  const filteredVendors = vendors.filter(vendor =>
    (vendor.name.toLowerCase().includes(filter.toLowerCase()) ||
    vendor.email.toLowerCase().includes(filter.toLowerCase()) ||
    vendor.phone.includes(filter) ||
    vendor.address.toLowerCase().includes(filter.toLowerCase()) ||
    vendor.aadhaar.includes(filter)) &&
    (statusFilter === "" || vendor.status === statusFilter)
  );

  return (
    <div>
      <div className="container-sub-header">
        <div className="left-buttons">
          <input
            type="text"
            placeholder="Search by name"
            value={filter}
            onChange={handleFilterChange}
            className="filter-input"
          />
          <select className="filter-input" value={statusFilter} onChange={handleStatusFilterChange}>
            <option value="">All Statuses</option>
            <option value="Active">Active</option>
            <option value="Pending">Pending</option>
            <option value="Blocked">Blocked</option>
          </select>
          <button className="action-button" onClick={handleViewVendors}>Vendor List</button>
        </div>
        <div className="right-buttons">
          <button className="action-button" onClick={() => setIsAddFormVisible(true)}>Add Vendor</button>
        </div>
      </div>

      <div className="vendor-container">
        {isAddFormVisible ? (
          <VendorForm onSubmit={handleAddVendor} />
        ) : isEditFormVisible ? (
          <EditVendorForm vendor={editingVendor} onSubmit={handleUpdateVendor} onCancel={closeEditForm} />
        ) : (
          <Switch>
            <Route path={`${path}`}>
              {filteredVendors.length === 0 ? (
                <VendorEmptyState content="No vendors available. Start adding vendors to manage them here." />
              ) : (
                <VendorList
                  vendors={filteredVendors}
                  onDelete={handleDeleteVendor}
                  onEdit={handleEditVendor}
                  onStatusChange={handleStatusChange}
                  statusChangeSuccess={statusChangeSuccess}
                />
              )}
            </Route>
          </Switch>
        )}
        {message && <div className={`message ${messageType}`}>{message}</div>}
      </div>
    </div>
  );
};

export default VendorManagementPage;